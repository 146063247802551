'use client';

import { useState } from 'react';
import { useInterval, useMount } from 'react-use';
import { TimeReadout } from './time-readout';
import { calculateDuration } from './calculate-duration';

interface LiveTimerProps {
  start: Date;
}
/**
 * A countdown or countup timer depending on whether the start date is in the future.
 */
export function LiveTimer({ start }: LiveTimerProps) {
  const { days, hours, minutes, seconds } = useLiveTimer(start);

  // prevent hydration error
  const [mounted, setMounted] = useState(false);
  useMount(() => setMounted(true));
  if (!mounted) return null;

  return (
    <TimeReadout
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
    />
  );
}

function useLiveTimer(start: Date) {
  const [duration, setDuration] = useState(
    calculateDuration(start, new Date()),
  );
  useInterval(() => {
    setDuration(calculateDuration(start, new Date()));
  }, 1000);

  return duration;
}
