interface TimeReadoutProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}
/**
 * Displays a duration in days, hours, minutes, and seconds.
 */
export function TimeReadout({
  days,
  hours,
  minutes,
  seconds,
}: TimeReadoutProps) {
  return (
    <div className="flex items-center gap-1">
      <TimeReadoutValue value={days} unit="days" />
      <TimeReadoutValue value={hours} unit="hours" />
      <TimeReadoutValue value={minutes} unit="mins" />
      <TimeReadoutValue value={seconds} unit="secs" />
    </div>
  );
}

interface TimeReadoutValueProps {
  value: number;
  unit: string;
}
function TimeReadoutValue({ value, unit }: TimeReadoutValueProps) {
  return (
    <div className="space-x-0.5">
      <span className="font-bold tabular-nums leading-3 tracking-wider">
        {value}
      </span>
      <span className="text-xs">{unit}</span>
    </div>
  );
}
