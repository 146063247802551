/**
 * Calculates the duration between two dates.
 */
export const calculateDuration = (start: Date, end: Date) => {
  const difference = Math.abs(+end - +start);
  let duration = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  if (difference > 0) {
    duration = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return duration;
};
